.root {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;

  /* Unset user agent styles */
  appearance: none;

  /* Borders */
  border: none;

  /* Effects */

  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  & input {
    &:focus {
      box-shadow: none;
    }
  }

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

.description {
  color: gray;
  font-size: smaller;
  font-weight: 600;

  margin-top: 5px;
  margin-bottom: 8px;
}

.select {
  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.note {
  margin-top: 6px;
  font-size: 14px;
}