@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex !important;
  flex-direction: row;
}

.swiper {
  display: flex;
  flex-direction: row;
  padding: 20px;
  max-width: 90vw;
}

.buttons {
  position: absolute;
  top: 40%;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: var(--marketplaceColor);
  height: fit-content;
}

.button {
  z-index: 2;
  border: none;
  transform: scale(100%);
  transition-duration: 200ms;

  @media (--viewportLarge) {
    transform: scale(120%);
  }

  &:hover {
    @media (--viewportLarge) {
      transform: scale(140%);
    }
  }

  &:last-child {
    margin-left: auto;
  }
}
