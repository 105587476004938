.root {
}

.fileWrapper {
  position: relative;
  border: 2px dashed var(--matterColor);
  border-radius: var(--borderRadius);
  width: 100%;
  height: 80px;
}

.removeFile {
  position: absolute;
  top: 0;
  right: 150px;
  cursor: pointer;
}

.rootForFile {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.file {
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
}

.fileName {
  display: flex;
  align-items: center;
  gap: 10px;
}