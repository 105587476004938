@import "../../styles/customMediaQueries.css";

.root {
  position: relative;
  padding: 20px 24px;
  border-radius: 8px;
  width: 312px;
  box-shadow: var(--boxShadowPopup);

  @media (--viewportLargeWithPaddings) {
    width: 360px;
    padding: 24px;
  }
}

.badge {
  position: absolute;
  top: 4px;
  right: 4px;
  margin: 0;
  padding: 4px;

  &.recommended {
    color: var(--colorAttention);
  }

  &.wereOnPlan,
  &.currentPlan {
    color: var(--colorGrey600);
  }
}

.title {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  @media (--viewportLargeWithPaddings) {
    font-size: 40px;
  }
}

.price {
  font-size: 16px;
  color: var(--colorBlack);
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);

  @media (--viewportLargeWithPaddings) {
    font-size: 18px;
  }
}

.description {
  margin: 0 0 16px 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey300);

  @media (--viewportLargeWithPaddings) {
    margin: 0 0 32px 0;
    font-size: 18px;
  }
}

.button {
  width: 100%;
}