@import '../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 48px;
}

.contactButton {
  flex: 0.4;

  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.avatar {
  flex-shrink: 0;
  margin-right: 24px;
}

.info {
  flex: 1;
}

.heading {
  composes: h3 from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}

.contactButtonContainerInMobile {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}