@import '../../styles/customMediaQueries.css';

.successHeader {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.title {
  font-size: 32px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 1.5;
  letter-spacing: -0.16px;
  color: var(--colorNeutralDarker);
  margin-bottom: 0;
  padding: 0;
  text-align: center;
}

.subtitle {
  text-align: center;
  color: var(--colorGrey400);
}

.dropdown {
  background-color: var(--colorGrey80);
  color: var(--colorBlack);
  border-radius: 2px;
}

.dropdownTitle {
  border: none;
  text-align: start;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  margin-left: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dropdownContent {
  width: 100%;
  text-align: start;
  padding: 10px 20px;
}

.insight {
  margin-top: 10px;
  background-color: var(--marketplaceColorBGLight);
  color: var(--colorBlack);
  border-radius: 2px;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;

  & p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.priceBox {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 0px 20px 10px 20px;
  border-radius: 3px;
  border: 1px solid var(--colorGrey100);

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.price {
  display: flex;
  flex-direction: row;
}

.priceDisplay {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
}

.originalPrice {
  color: var(--colorGrey300);
  text-decoration: line-through;
}

.button {
  margin-top: 20px;
  &:hover {
    text-decoration: none;
  }
}

.offerTitle {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  overflow: hidden;
  gap: 0px;
  margin: auto;
  top: -15px;

  & div {
    padding: 0px 12px;
    font-size: 8px;
    display: flex;
    align-items: center;

    @media (--viewportMedium) {
      padding: 2px 12px;
      font-size: 12px;
    }

    @media (--viewportLarge) {
      padding: 4px 15px;
      font-size: 14px;
    }
  }

  & .title {
    background-color: var(--marketplaceColorLight);
    color: white;
    width: fit-content;
  }

  & .percent {
    background-color: var(--marketplaceColorBGLight);
    color: var(--marketplaceColorLight);
  }
}
