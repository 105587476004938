@import '../../styles/customMediaQueries.css';

.buttons {
  margin-top: 24px;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & button {
    width: 100%;

    @media(--viewportSmall) {
      width: 30%;
    }
  }

  & button:only-child {
    margin-left: auto;
  }
}

.error {
  composes: textSmall from global;
  color: var(--colorFail);
  font-style: italic;
}
