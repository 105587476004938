@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid var(--colorGrey200);
  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadow);
  padding: 24px 28px;
  min-height: 125px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    box-shadow: var(--boxShadowPopupLight);
  }

  @media (--viewportMedium) {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid var(--colorGrey200);
    border-radius: 0px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      background-color: var(--colorGrey100);
      cursor: pointer;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
    gap: 48px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  composes: h4 from global;
  margin: 0;
}

.listingSummary {
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
  margin: 4px 0;
}

.address {
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey400);
  margin: 4px 0;
}

.contactButton {
  @media (--viewportLarge) {
    flex: 0 0 30%;
    max-width: 300px;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.header {
  display: flex;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }
}

.viewDetailsLink {
  margin-left: auto;
  font-size: small;
}
